<template>
	<div class="register">
		<div class="box-top"></div>
		<div class="box-center">
			<div>
				<div class="box-logo">
					<img src="../assets/mask/login/logo.png" style="width: 119px; height: 118px" />
				</div>
        <el-form ref="form" :model="form" :rules="loginRules" label-width="0">
          <div class="content">
            <div style="text-align: center;color: #1577CB;">
              注册VIP会员
              <div class="box-title">
                需支付VIP会员年费：￥1/年 
              </div>
            </div>
            <el-form-item prop="phone">
              <el-input placeholder="请输入手机号" v-model="form.phone" clearable>
                <img slot="prefix"
                  src="../assets/mask/login/sj.png" 
                  style="width: 22px; height: 22px;padding-top: 17px;" />
              </el-input>
            </el-form-item>
            <el-form-item prop="value">
              <el-input placeholder="请输入验证码" v-model="form.value">
                <el-button 
                  slot="suffix" 
                  size="mini" 
                  type="primary" 
                  style="font-size: 14px;margin-top: 13px;"
                  :disabled="btnDisabled"
                  @click="sendSMS">
                    {{!btnDisabled ? '获取验证码' : countLoading + '秒后可重发'}}
                  </el-button>
                <img slot="prefix"
                  src="../assets/mask/login/yzm.png" 
                  style="width: 22px; height: 22px;padding-top: 17px;" />
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入密码" v-model="form.password" show-password clearable>
                <img slot="prefix"
                  src="../assets/mask/login/mm.png" 
                  style="width: 22px; height: 22px;padding-top: 17px;" />
              </el-input>
            </el-form-item>
              <el-input placeholder="请再次输入密码" v-model="password" show-password clearable>
                <img slot="prefix"
                  src="../assets/mask/login/mm.png" 
                  style="width: 22px; height: 22px;padding-top: 17px;" />
              </el-input>
          </div>
          <div class="footer-btn">
            <el-button type="warning" @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleSave('form')">保存</el-button>
          </div>
        </el-form>
			</div>
			
			<div class="floor">
				Copyright ©2022-2025  Mask版权所有
			</div>
		</div>
		<el-dialog
      :visible.sync="dialogVisible"
      width="480px"
      :before-close="handleClose">
			<div class="formInfo">
				<div>
					请用微信扫码支付会员年费
				</div>
				<div class="content-coder">
					<vue-qr :text="QRcode" :size="200"></vue-qr>
				</div>
				<div class="bottom">
					需支付VIP会员年费：￥{{totalAmount}}/年 
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { isValidMobileNumber } from "../utils";
import { smsSendRegister, register, payment, getMemberorderDetail } from "../api";
import vueQr from 'vue-qr'
	export default {
    name: 'register',
    components: {
      vueQr
    },
    data() {
			return {
        countLoading: 60,
        btnDisabled: false,
        form: {
          phone: '',
          value: '',
          password: '',
          id: ''
        },
				password: '',
				dialogVisible: false,
        loginRules: {
          phone: [
            {required: true, message: "请输入手机号", trigger: "blur"}
          ],
          value: [
            {required: true, message: "请输入验证码", trigger: "blur"}
          ],
          password: [
            {required: true, message: "请输入密码", trigger: "blur"}
          ],
        },
        totalAmount: '',
        QRcode: '',
        orderId: '',
        orderTime: null,
			};
		},
		methods: {
      sendSMS(){
        if (!isValidMobileNumber(this.form.phone)) {
          this.$message.error('请输入正确手机号');
          return;
        }
        this.btnDisabled = true;
        this.countLoading = 60;
        var time = setInterval(() => {
          this.countLoading--;
          if(this.countLoading <= 0){
            this.btnDisabled = false;
            clearInterval(time);
          }
        },1000);
        smsSendRegister({phone: this.form.phone}).then(({ data, code }) => {
          if (code === 200) {
            this.form.id = data.id;
          }
        });
      },
			handleSave(form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            if (!isValidMobileNumber(this.form.phone)) {
              this.$message.error('请输入正确手机号');
              return;
            }
            if (this.form.password !== this.password) {
              this.$message.error('两次输入的密码不一致');
              return ;
            }
            const { phone, value, password, id } = this.form;
            const newPassword = this.$sha1(this.$md5(this.form.password));
            register({
              phone,
              value,
              password: newPassword,
              id
            })
              .then((res)=> {
                this.$store.dispatch("updateUserInfo", res);
                payment({ qty: 1 }).then(({data})=>{
                  this.QRcode = data.paySign.codeUrl;
                  this.totalAmount = data.totalAmount;
                  this.dialogVisible = true;
                  this.handleInquire(data.orderId);
                });
              })
              .finally(() => {
                this.fullscreenLoading = false;
              });
          } else {
            return ;
          }
        });
			},
      handleInquire(orderId) {
        const id = orderId;
        this.orderTime = setInterval(() => {
          getMemberorderDetail({id}).then((res) => {
            if(res.data.isPay === 1){
              this.dialogVisible = false;
              clearInterval(this.orderTime);
              this.$message({
                message: '支付成功',
                type: 'success'
              });
              this.$router.replace({
                path: "/criterion",
              });
            }
          });
        },3000);
      },
      handleCancel() {
        this.$router.push({path:'/login'});
      },
			handleClose() {
        clearInterval(this.orderTime);
        this.dialogVisible = false;
			},
			handleReturn() {
				this.$router.go(-1);
			},
		}
	}
</script>

<style lang="less">
	.register{
		.box-top{
			width: 100%;
			height: 440px;
			background: #444A59;
			position: absolute;
			z-index: -1;
		}
		.box-center{
			width: 430px;
			margin: 0 auto;
			
			.box-logo{
				padding-top: 60px;
				padding-bottom: 35px;
				text-align: center;
			}
		}
		.content{
			width: 332px;
			background-color: #fff;
			border-radius: 5px;
			padding: 15px 49px 0 49px;
			
			.box-title{
				margin-top: 8px;
				margin-bottom: 30px;
				color: #DAB87A;
				font-size: 15px;
			}
			
			.el-input__inner:hover,
			.el-input__inner{
				border-radius: 0;
				border: 1px solid #fff;
				border-bottom: 1px solid #E6E6E6;
			}
			.el-input--prefix .el-input__inner {
				height: 55px;
				padding-left: 40px;
			}
			
			.el-button--primary:focus,
			.el-button--primary:hover,
			.el-button--primary{
				background-color: #1577CB;
				border-color: #1577CB;
			}
			
		}
		.floor{
			font-size: 14px;
			color: #C8C8C8;
			padding-left: 90px;
			position: absolute;
			bottom: 10px;
			z-index: -1;
		}
		
		.formInfo{
			width: 280px;
			margin: 10px auto;
			font-size: 18px;
			text-align: center;
			.content-coder {
				width: 200px;
				height: 200px;
				margin: 20px auto;
			}
			.bottom {
				color: #DAB87A;
			}
		}
		
		.footer-btn{
			padding-top: 40px;
			background: #fff;
			border-radius: 0 0 5px 5px;
			.el-button+.el-button{
				margin-left: 0;
			}
			.el-button--warning:focus,
			.el-button--warning:hover,
			.el-button--warning {
				background-color: #DAB87A;
				border-color: #DAB87A;
				width: 215px;
				font-size: 18px;
				border-radius: 0 0 0 5px;
			}
			
			.el-button--primary:focus,
			.el-button--primary:hover,
			.el-button--primary{
				background-color: #1577CB;
				border-color: #1577CB;
				width: 215px;
				font-size: 18px;
				border-radius: 0 0 5px 0;
			}
		}
	}
</style>
